<script>
import { onMounted } from "vue"

export default {
  name: "Partners",

  setup() {
    onMounted(() => {
      window.scrollTo(0, 0)
    })
  }
}
</script>

<template>
  <div class="privacy-policy-section">
    <div class="container">
      <h1 class="policy-header">Marketing Partners include but are not limited to:</h1>
      <ul>
        <li>360 Media Direct</li>
        <li>4 Legal Leads</li>
        <li>5 Mile Media</li>
        <li>ABS Healthcare</li>
        <li>Absolute Health Agents</li>
        <li>Accident.com</li>
        <li>Ace Solutions</li>
        <li>Admaric</li>
        <li>Admediary</li>
        <li>Adsparkx Digital</li>
        <li>Advantage One Insurance</li>
        <li>Advocator Group</li>
        <li>Aetna</li>
        <li>AgedLeadStore.com</li>
        <li>AHCP</li>
        <li>AIG</li>
        <li>All Digital Promotions</li>
        <li>All Web Leads</li>
        <li>Allied Insurance</li>
        <li>Allied Insurance Partners (view list)</li>
        <li>Allstarz</li>
        <li>Alphatech Resource Holdings s.r.o.</li>
        <li>America’s Health Advisors</li>
        <li>American Auto Club</li>
        <li>American Continental</li>
        <li>American Disability Helpline</li>
        <li>American Family</li>
        <li>American Health Reform Solutions</li>
        <li>American Insurance Organization, LLC</li>
        <li>American Republic</li>
        <li>American Select Health</li>
        <li>Andy Montague Region</li>
        <li>Anthem/BCBS</li>
        <li>Assurant, Bankers Life and Casualty</li>
        <li>ATI Agency</li>
        <li>Audience Serv</li>
        <li>AutoGuard Pro</li>
        <li>Automotive Services Center</li>
        <li>Bad Ass Leads</li>
        <li>Bankrate</li>
        <li>BBRS Group</li>
        <li>Beacon Leads, LLC</li>
        <li>Benefit Advisors</li>
        <li>Best Case Leads</li>
        <li>Best Insurance Group</li>
        <li>BestQuotes</li>
        <li>Better PPC</li>
        <li>Blue Nile</li>
        <li>BlueCross/Blue Shield Association Companies</li>
        <li>Bold Media Group</li>
        <li>Boost</li>
        <li>Boost Health Insurance</li>
        <li>Boost Marketing Inc</li>
        <li>Btel</li>
        <li>Btel Communications</li>
        <li>C3 Data, LLC</li>
        <li>Cadence Media Partners</li>
        <li>Call Center Partnerships</li>
        <li>Cedar Tree Media</li>
        <li>Cege Media</li>
        <li>Choice Direct</li>
        <li>Choice One Health & Life Agency</li>
        <li>Cigna, Coventry, Fortis</li>
        <li>Citirex</li>
        <li>Citizens Disability</li>
        <li>Clean Energy Concepts</li>
        <li>Clearlink</li>
        <li>Compass Health Advisors</li>
        <li>Connect Plus</li>
        <li>Connect Rite Bpo</li>
        <li>Contactability</li>
        <li>Continuume</li>
        <li>Corner Shop Media</li>
        <li>Crosspointe Insurance Advisors</li>
        <li>D and D Investment Specialist</li>
        <li>Datalot</li>
        <li>Datwerx</li>
        <li>Diablo Media</li>
        <li>Digital Market Media</li>
        <li>Direct Web Advertising</li>
        <li>Disability Advisor</li>
        <li>DiscoverBPO</li>
        <li>DKS Marketing</li>
        <li>DPMP Media Inc.</li>
        <li>Dudley Dobosier</li>
        <li>e-TeleQuote Insurance, Inc.</li>
        <li>Elocal</li>
        <li>EMedia</li>
        <li>Emerald Enterprise Corp</li>
        <li>Energy Alliance</li>
        <li>Everquote</li>
        <li>Everquote's Marketing Partners</li>
        <li>Excel Impact</li>
        <li>Family First Health Plans</li>
        <li>Family First Life</li>
        <li>FCHCN Insurance Agency</li>
        <li>Field of Leads Ltd.</li>
        <li>Fields of Leads</li>
        <li>Final Expense Assistant</li>
        <li>Financial Grade</li>
        <li>First Family</li>
        <li>FirstAmericanFinancial</li>
        <li>FiveStrata</li>
        <li>Flyhigh</li>
        <li>Genworth Financial</li>
        <li>Gerber Life</li>
        <li>GHI</li>
        <li>GigUp</li>
        <li>Globalsky BPO</li>
        <li>GoHealth</li>
        <li>Golden Rule</li>
        <li>Grey Peaks</li>
        <li>Grow My Firm Online</li>
        <li>Guardian Benefits</li>
        <li>Guide to Insure</li>
        <li>Guidestar Marketing Group LLC</li>
        <li>HasTraffic</li>
        <li>HauteProspects</li>
        <li>Health Benefits Center</li>
        <li>Health Benefits One</li>
        <li>Health Dime</li>
        <li>Health Insurance Advantage</li>
        <li>Health Insurance Services</li>
        <li>Health Net</li>
        <li>Healthcare.com</li>
        <li>HealthIQ</li>
        <li>HealthMarkets/Insphere</li>
        <li>HealthPlanOne</li>
        <li>HealthSpire Calls</li>
        <li>Heard and Smith</li>
        <li>Highmark</li>
        <li>HK Flavors Limited</li>
        <li>Home Bundles</li>
        <li>Home Path</li>
        <li>Home Savings</li>
        <li>HomeWarrantyProject.com</li>
        <li>HP One</li>
        <li>HPO</li>
        <li>Humana</li>
        <li>iCall Solutions</li>
        <li>Ideal Concepts</li>
        <li>Ideal Concepts, Inc</li>
        <li>iLegacy Insurance</li>
        <li>Inbounds LLC</li>
        <li>Innovate Financial Group</li>
        <li>Innovative Financial Group</li>
        <li>Inside Response</li>
        <li>Insphere</li>
        <li>Insuralife</li>
        <li>Insurance Guide</li>
        <li>Insurance Line One</li>
        <li>Insurance Services</li>
        <li>Insurance Solutions Direct LLC</li>
        <li>Insurance Solutions LLC</li>
        <li>Kaiser, Medicare Solutions</li>
        <li>Kallstar</li>
        <li>KB Synergy</li>
        <li>Kevin Ferrell Inc</li>
        <li>Lead Origins</li>
        <li>Lead Prosper LLC</li>
        <li>Lead Realm LLC</li>
        <li>Lead to the Top</li>
        <li>Legal Brand Marketing</li>
        <li>Legal Fish</li>
        <li>Legends United Insurance Agency</li>
        <li>Lender Daily</li>
        <li>Lendmarx</li>
        <li>Lighthouse Insurance Group</li>
        <li>Line East Leads</li>
        <li>ListenClear</li>
        <li>LizDev Inc.</li>
        <li>LizDev, Inc</li>
        <li>Mantra Digital</li>
        <li>Media Power Marketing</li>
        <li>Medicare Concepts, LLC</li>
        <li>Momentum Solar</li>
        <li>Mutual of Omaha</li>
        <li>MyHealthAngel.com</li>
        <li>Narrate LLC</li>
        <li>National Automotive Financial Services</li>
        <li>National Disability</li>
        <li>National Health Connect LLC</li>
        <li>National Health Hub LLC</li>
        <li>National Healthcare Advisors</li>
        <li>National Income Life Insurance Company</li>
        <li>National Repair Solutions</li>
        <li>Nationwide Health Advisors</li>
        <li>Next Wave Marketing</li>
        <li>Next Wave Marketing Strategies, Inc</li>
        <li>NextGen Leads</li>
        <li>Northwest Medical</li>
        <li>Number One Health Insurance Agency</li>
        <li>Number One Prospecting</li>
        <li>Oakwood Legal</li>
        <li>Oncore</li>
        <li>One Health</li>
        <li>Onyx Digital Media</li>
        <li>Open Jar Concepts</li>
        <li>OpenJar Concepts</li>
        <li>Otis Production LLC</li>
        <li>Ox Car Care</li>
        <li>Palo Media Group</li>
        <li>PCS</li>
        <li>Peak Performance</li>
        <li>Ping Leads</li>
        <li>Pinnacle Publishers LLC</li>
        <li>Policy Scout</li>
        <li>PolicyBind, LLC</li>
        <li>PolicyScout</li>
        <li>Power Credit Solutions</li>
        <li>Power Source Marketing</li>
        <li>Power Source Marketing LLC</li>
        <li>Practical Marketing</li>
        <li>Precise Leads</li>
        <li>Premier Disability</li>
        <li>Premier Financial Alliance</li>
        <li>Premier Health Advisors, Inc.</li>
        <li>Premier Health Associates</li>
        <li>Premier Insurance Benefits, LLC</li>
        <li>Presidio Interactive</li>
        <li>Presidio Interactive Corp</li>
        <li>Primerica</li>
        <li>Prospex Digital</li>
        <li>Purple Dog Marketing LLC</li>
        <li>Q3M Insurance Solutions</li>
        <li>Qatalyst</li>
        <li>Quantum 3 Media LLC</li>
        <li>QuickQuote</li>
        <li>Quote Velocity</li>
        <li>Quotefire</li>
        <li>QuoteManage LLC</li>
        <li>QuoteStorm</li>
        <li>Quotewizard</li>
        <li>Rank Media</li>
        <li>RateMarketPlace</li>
        <li>Reliable Partners (Trips Marketing)</li>
        <li>Reliance Litigation</li>
        <li>Resource Marketing Corp.</li>
        <li>ReviMedia</li>
        <li>Riddle Pharmacy</li>
        <li>Rising Results, Inc.</li>
        <li>Rogue E-Cigs</li>
        <li>S.B. Fintech Ltd</li>
        <li>SalesHammer</li>
        <li>Satereh Law</li>
        <li>Seascape</li>
        <li>Seascape Health</li>
        <li>Seascape Life</li>
        <li>Secure Horizons</li>
        <li>Select Quote</li>
        <li>SelectQuote</li>
        <li>SeniorCare USA</li>
        <li>Simple Health</li>
        <li>Simple Health Plans Inc.</li>
        <li>Simple Insurance Leads</li>
        <li>Singularity Media</li>
        <li>Smart Match Insurance Agency</li>
        <li>Smart Match Insurance Solutions</li>
        <li>SmartHome Solutions USA</li>
        <li>SmartScripts & Affiliates</li>
        <li>SmartScripts Agents</li>
        <li>Solar Direct Marketing</li>
        <li>SolidQuote</li>
        <li>Spring EQ</li>
        <li>Spring Venture Group</li>
        <li>SS Telemarketing</li>
        <li>Sterling</li>
        <li>StoneTapert Insurance Services Inc</li>
        <li>SubjectWell</li>
        <li>Sun City Financial LLC</li>
        <li>Support First</li>
        <li>Synergy Marketing Associates</li>
        <li>Tallac Media</li>
        <li>The Medicare Assistant</li>
        <li>The-Solar-Project</li>
        <li>Thrive Marketing</li>
        <li>Tiger Health Solutions</li>
        <li>TM Global</li>
        <li>Torchlight Technology Group LLC</li>
        <li>TradeMarc Global, LLC</li>
        <li>Traffic Panda</li>
        <li>Trips Marketing LLC</li>
        <li>Trusted Consumer Advisors</li>
        <li>TZ Insurance Solutions LLC</li>
        <li>UE Authority Partners</li>
        <li>United Access insurance</li>
        <li>United Healthcare</li>
        <li>United Insurance Group Agency, Inc.</li>
        <li>United Medicare</li>
        <li>United Medicare Advisors</li>
        <li>Universal American</li>
        <li>US Dealer Services</li>
        <li>US Health Advisors</li>
        <li>US Health Group</li>
        <li>US Insurance Advisors, LLC</li>
        <li>US Marketing Group</li>
        <li>USHEALTH Advisors</li>
        <li>Vertigen LLC</li>
        <li>Visiqua</li>
        <li>Voxify</li>
        <li>Voxify LLC</li>
        <li>Walker Advertising</li>
        <li>WeCall</li>
        <li>WellPoint</li>
        <li>West Coast Closers</li>
        <li>Wicked Good Leads</li>
        <li>Yasha Marketing</li>
        <li>Your Best Leads</li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @media(max-width: 1100px) {
    .privacy-policy-section .container ul {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media(max-width: 750px) {
    .privacy-policy-section .container ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media(max-width: 550px) {
    .privacy-policy-section .container ul {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
</style>